@charset "UTF-8";
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	width: 100%;
	font-family: "Baloo Bhai 2";
	background-color: #f6f6f6;
}
body::-webkit-scrollbar {
	width: 1em;
	background-color: #f6f6f6;
}
body::-webkit-scrollbar-track {
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
body::-webkit-scrollbar-thumb {
	background-color: #0042ff;
}
.login {
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: white;
}
.login-box {
	width: 40%;
}
.login-text {
	text-align: center;
	font-size: 1.2rem;
	font-weight: bold;
	margin-bottom: 1.5rem;
}
.side-nav-content {
	width: 15%;
	background-color: #0042ff;
	height: 100vh;
	position: fixed;
	padding: 2rem 1.5rem;
}

.admin-logo {
	width: 9.25rem;
	height: auto;
}
.admin-logo img {
	height: auto;
	width: 100%;
}

.sides {
	list-style: none;
	margin: 2rem 0;
}

.side-link {
	display: flex;
	color: white;
	padding: 0.8rem 0;
	align-items: center;
}
.side-link i {
	padding-right: 1rem;
	color: white;
}
.side-link a {
	color: white;
	font-family: "Baloo Bhai 2";
	font-size: 14px;
	font-weight: 500;
	letter-spacing: 0;
	line-height: 20px;
}

.side-link.active i {
	color: #2fdf84;
}
.side-link.active a {
	font-size: 15px;
	font-weight: bold;
}

.main-content {
	width: 85%;
	margin: 0 0 0 auto;
}

.admin-container {
	width: 95%;
	margin: 0 auto;
	height: 100%;
}

.top-nav {
	height: 70px;
	width: 100%;
	background-color: #ffffff;
}

.top-nav-content {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 100%;
}

.pm-page {
	color: #000000;
	font-family: "Baloo Bhai 2";
	font-size: 22px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 20px;
	margin: 0;
}

.dropp {
	position: relative;
}
.user-nav {
	display: flex;
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
	align-items: center;
	position: relative;
	cursor: pointer;
}

.user-greetings {
	color: #000000;
	font-family: "Baloo Bhai 2";
	font-size: 14px;
	font-weight: 600;
	letter-spacing: 0;
	line-height: 20px;
	text-align: right;
	margin-right: 0.5rem;
	user-select: none;
}

.user-avatar {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	/* border: 1px solid rgba(0, 0, 0, 0.685); */
	margin-right: 0.5rem;
	background-color: #0042ff;
	display: flex;
	justify-content: center;
	overflow: hidden;
	color: white;
	font-size: 15px;
	font-weight: bold;
	align-items: center;
}
.user-avatar img {
	width: 100%;
	height: 100%;
	-o-object-fit: cover;
	object-fit: cover;
}

.drop-down {
	background-color: white;
	box-shadow: 0 0 3px rgba(118, 118, 128, 0.2);
	width: 10rem;
	height: auto;
	padding: 1rem 0;
	position: absolute;
	top: 105%;
	right: 0;
	z-index: 3;
	display: none;
}
.drop-down ul {
	list-style: none;
	margin: 0;
}
.drop-down ul li {
	font-size: 0.9rem;
	font-weight: 600;
	padding: 0.5rem 1rem;
	user-select: none;
}
.drop-down ul li a {
	color: black;
}
.drop-down ul li:hover {
	background-color: #0042ff;
	color: white;
	text-decoration: underline;
	cursor: pointer;
}
.drop-down ul li:hover a {
	color: white;
}

.drop-down.active {
	display: block;
}

.inner-content {
	width: 100%;
	margin-top: 3.7rem;
}

.pm-card {
	width: 100%;
	height: 100%;
	overflow: hidden;
	/* box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.12); */
}

.pm-card-head {
	width: 100%;
	height: 4rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 1.25rem;
	font-size: 1rem;
	font-weight: 600;
	letter-spacing: 0;
	line-height: 20px;
	background-color: white;
	margin-bottom: 0.1rem;
	border-radius: 6px 6px 0 0;
}

.pm-card-body {
	width: 100%;
	min-height: 5.3rem;
	display: flex;
	align-items: center;
	padding: 0 1.25rem;
	font-size: 1.88rem;
	font-weight: 600;
	letter-spacing: 0;
	line-height: 20px;
	background-color: white;
	border-radius: 0 0 6px 6px;
}

.pm-chart-box {
	height: 16.7rem;
}

.pm-user-box {
	height: 40rem;
}
.pm-user-box ol {
	list-style: none;
	counter-reset: my-awesome-counter;
	margin-top: 0.5rem;
}
.pm-user-box ol li {
	display: flex;
	align-items: center;
	counter-increment: my-awesome-counter;
	padding: 1rem 0;
}
.pm-user-box ol li::before {
	content: counter(my-awesome-counter);
	font-size: 14px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 20px;
	margin-right: 1rem;
}

.user-pic {
	width: 2.5rem;
	height: 2.5rem;
	border-radius: 50%;
	background-color: #0042ff;
	display: flex;
	justify-content: center;
	overflow: hidden;
	color: white;
	font-size: 14px;
	align-items: center;
	justify-content: center;
	flex: 0 0 2.5rem;
}
.user-pic img {
	width: 100%;
	height: 100%;
	-o-object-fit: cover;
	object-fit: cover;
	-o-object-position: top;
	object-position: top;
}

.user-info {
	margin-left: 1rem;
}
.user-info h4 {
	font-size: 1rem;
	color: black;
	font-weight: 600;
	letter-spacing: 0;
	line-height: 16px;
	margin-bottom: -5px;
}
.user-info span {
	font-size: 10px;
	color: rgba(0, 0, 0, 0.9);
	font-weight: 500;
	letter-spacing: 0;
	line-height: 16px;
}

.pm-select {
	border: 1px solid rgba(36, 68, 115, 0.14);
	border-radius: 3px;
	background-color: #ffffff;
	height: 2.1rem;
	display: flex;
	align-items: center;
	margin-left: 0.5rem;
}
.pm-select select {
	border: 0;
	font-size: 12px;
	font-weight: 500;
	letter-spacing: 0;
	line-height: 20px;
	color: #4e4e4e;
	font-family: "Baloo Bhai 2";
	outline: 0;
	padding: 0 0.8rem;
}

.admin-inner {
	margin-top: 2rem;
	width: 96%;
	margin-left: 1rem;
	height: 100%;
	margin-bottom: 3rem;
}

.flex {
	display: flex;
	align-items: flex-end;
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
}

.flex.top {
	align-items: flex-start !important;
}

.flex.divided {
	flex-direction: row;
}

.flex-center {
	display: flex;
	justify-content: center;
	height: 100%;
}

.flex-item {
	display: flex;
	justify-content: space-between;
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
	align-items: center;
	width: 100%;
}

.list-users {
	width: 20%;
	height: 100%;
}

.list-user-wrapper {
	min-height: 618px;
	background-color: white;
}

.user-content-box {
	width: 79%;
	margin-left: auto;
	min-height: 600px;
	background-color: #ffffff;
	padding: 1rem 2rem;
}

.admin-search-bar {
	position: relative;
	width: 100%;
}
.admin-search-bar i {
	position: absolute;
	top: 50%;
	left: 10%;
	transform: translateY(-50%);
}

.admin-search {
	height: 45px;
	width: 100%;
	background-color: #ffffff;
	border: 0;
	outline: 0;
	padding: 0 0 0 2.5rem;
	color: rgba(0, 0, 0, 0.301);
	font-family: "Baloo Bhai 2";
	font-size: 14px;
	font-weight: 500;
	letter-spacing: 0;
	line-height: 20px;
}

.user-box,
.user-box-l {
	height: 40px;
	width: 100%;
	background-color: #ffffff;
	border-top: 1px solid rgba(36, 68, 115, 0.3);
	display: flex;
	align-items: center;
	padding-left: 1rem;
	color: black;
	font-family: "Baloo Bhai 2";
	font-size: 14px;
	font-weight: 600;
	letter-spacing: 0;
	line-height: 20px;
	cursor: pointer;
}
.user-box:hover,
.user-box-l:hover {
	background-color: #0042ff;
	color: #ffffff;
}

.user-box-l {
	border-bottom: 1px solid rgba(36, 68, 115, 0.3);
	border-top: 0;
	border-right: 0;
}

.pagination {
	display: flex;
	margin: 0.8rem 0;
	justify-content: center;
}

.page-arrows,
.page-number {
	box-sizing: border-box;
	height: 27px;
	width: 27px;
	border: 0.5px solid rgba(208, 208, 208, 0.24);
	border-radius: 2px 0 0 2px;
	background-color: #ffffff;
	color: rgba(0, 0, 0, 0.301);
	font-family: "Font Awesome 5 Pro";
	font-size: 14px;
	font-weight: 300;
	letter-spacing: 0;
	line-height: 14px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
}
.page-arrows.disabled {
	pointer-events: none;
	cursor: not-allowed;
}
.page-number {
	color: #000000;
	font-family: "Baloo Bhai 2";
	font-size: 14px;
	font-weight: 500;
	letter-spacing: 0;
	line-height: 14px;
}

.nb {
	background-color: transparent;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	border: 0;
	padding: 0 0.8rem;
}

.admin-tab {
	display: flex;
	width: 100%;
	margin-top: 1.5rem;
	border-bottom: 0.5px solid rgba(36, 68, 115, 0.1);
}

.single-admin-tab {
	height: 36px;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	padding: 0 1.5rem;
	border-radius: 2px 16px 0 2px;
	background-color: rgba(36, 68, 115, 0.03);
	font-family: "Baloo Bhai 2";
	font-size: 14px;
	font-weight: 600;
	letter-spacing: 0;
	line-height: 24px;
	text-align: center;
	color: black;
	display: flex;
	align-items: center;
	cursor: pointer;
}

.single-admin-tab.current {
	background-color: #0042ff;
	color: #ffffff;
}

.profile-tab-content {
	margin: 2rem 0;
}

.per-details {
	margin: 1rem 0;
}

.tab-label,
.tab-text {
	color: rgba(15, 15, 15, 0.5);
	font-family: "Baloo Bhai 2";
	font-size: 12px;
	font-weight: 500;
	letter-spacing: 0;
	line-height: 16px;
	display: block;
}

.tab-text {
	color: #0f0f0f;
	font-size: 14px;
	line-height: 18px;
	font-weight: 600;
}

.checkbox-text {
	color: #6d7278;
	font-family: "Baloo Bhai 2";
	font-size: 14px;
	font-weight: 600;
	letter-spacing: 0;
	line-height: 20px;
	margin-right: 1rem;
}

.ag-checkbox {
	position: relative;
}

.switch {
	display: flex;
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
	align-items: center;
	margin-bottom: 1rem;
	margin-top: 1.5rem;
}

.ag-toggle {
	position: relative;
	display: inline-block;
	width: 2rem;
	height: 1.25rem;
	margin-bottom: 0;
}
.ag-toggle input {
	opacity: 0;
	width: 0;
	height: 0;
}

.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: #e6e6e6;
	border-radius: 10px;
	transition: 0.4s;
}
.slider::before {
	position: absolute;
	content: "";
	height: 1rem;
	width: 1rem;
	top: 2px;
	left: 2px;
	border-radius: 50%;
	background-color: white;
	transition: 0.4s;
}

input:checked + .slider {
	background-color: #191919;
}

input:checked + .slider::before {
	transform: translateX(80%);
}

.admin-rating {
	height: 73px;
	width: 175px;
	border-radius: 7.5px;
	background-color: #fafafa;
	padding: 1rem;
}
.admin-rating i {
	color: #e5eaf2;
}

.rate-text {
	color: #000000;
	font-family: "Baloo Bhai 2";
	font-size: 9px;
	font-weight: 600;
	letter-spacing: -0.3px;
	line-height: 14px;
	text-align: center;
}

.user-name-a {
	color: #000000;
	font-family: "Baloo Bhai 2";
	font-size: 20px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 30px;
	margin-bottom: 0;
}

.user-loc-a {
	height: 31px;
	width: 245px;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	border-radius: 10px;
	margin: 0 1rem;
	background-color: rgba(216, 216, 216, 0.13);
}

.user-red-btn-a {
	height: 40px;
	width: 180px;
	border-radius: 6px;
	background-color: #0042ff;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #ffffff;
	font-family: "Baloo Bhai 2";
	font-size: 16px;
	font-weight: 600;
	letter-spacing: -0.53px;
	line-height: 20px;
	border: 0;
	outline: 0;
}
.user-red-btn-a i {
	color: #ffffff;
	font-family: "Font Awesome 5 Pro";
	font-size: 14px;
	letter-spacing: -0.47px;
	line-height: 20px;
	text-align: center;
	margin-right: 0.5rem;
}

.network-tab-title {
	color: #000000;
	font-family: "Baloo Bhai 2";
	font-size: 16px;
	font-weight: 600;
	letter-spacing: -0.53px;
	line-height: 26px;
}

.network-count {
	color: #000000;
	font-family: "Baloo Bhai 2";
	font-size: 30px;
	font-weight: 600;
	letter-spacing: 0;
	line-height: 20px;
}

.per-content {
	display: block;
	margin: 1rem 0;
}

.ag-thead {
	background-color: #f7f7f7;
	height: 43px;
}
.ag-thead tr th {
	color: #000000;
	font-family: "Baloo Bhai 2";
	vertical-align: middle;
	font-size: 16px;
	font-weight: 600;
	letter-spacing: 0;
	padding: 0 0.75rem;
	line-height: 26px;
}

.single-listing {
	margin-bottom: 1.25rem;
}

.listing-img {
	height: 5rem;
	width: 5rem;
	border-radius: 6px;
	background-color: #f6f6f6;
	overflow: hidden;
	margin-right: 0.5rem;
	flex: 0 0 5rem;
}
.listing-img img {
	width: 100%;
	height: 100%;
	-o-object-fit: cover;
	object-fit: cover;
}

.listing-title {
	color: #000000;
	font-size: 18px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 30px;
	margin-bottom: 2rem;
}

.listing-info {
	min-height: 5rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	border-bottom: 1px solid #f6f6f6;
}
.listing-info h6 {
	color: #252427;
	font-family: "Baloo Bhai 2";
	font-size: 14px;
	font-weight: 600;
	letter-spacing: 0;
	line-height: 16px;
	width: 160px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	margin-bottom: 0;
}
.listing-info span {
	font-size: 14px;
	font-weight: 400;
	color: #252427;
}
.listing-info small {
	color: #0042ff;
	font-size: 14px;
	width: 80px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.listing-info i {
	margin-right: 0.4rem;
	color: #0042ff;
}

.inner-wrapper {
	background-color: white;
	min-height: 600px;
	width: 100%;
	padding: 1rem;
}

.admin-action {
	height: 2.5rem;
	border: 2px solid #191919;
	border-radius: 3.2px;
	padding: 0 1rem;
	background-color: rgba(255, 255, 255, 0);
	display: flex;
	justify-content: center;
	align-items: center;
	outline: 0;
	margin-left: 1rem;
	font-size: 14.4px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 23px;
	text-align: center;
	color: black;
	cursor: pointer;
}
.admin-action select {
	border: 0;
	outline: 0;
	font-size: 14.4px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 23px;
	text-align: center;
}

.admin-search-bar-2 {
	border: 2px solid #191919;
	border-radius: 3.2px;
	height: 2.5rem;
	display: flex;
	justify-content: center;
	align-items: center;
}
.admin-search-bar-2 .property-search {
	border: 0;
	outline: 0;
	margin-left: 1rem;
	font-weight: 600;
}
.admin-search-bar-2 i {
	font-size: 14.4px;
	margin-left: 0.8rem;
}

tbody tr th {
	color: #000000;
	font-family: "Baloo Bhai 2";
	font-size: 14px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 20px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 150px;
	cursor: pointer;
	display: flex;
	align-items: center;
	vertical-align: middle !important;
	border-top: none !important;
}

tbody tr td {
	color: #000000;
	font-family: "Baloo Bhai 2";
	font-size: 14px;
	font-weight: 600;
	letter-spacing: 0;
	line-height: 20px;
	max-width: 150px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	vertical-align: middle !important;
	border-top: none !important;
}
tbody tr td.delete {
	vertical-align: top !important;
}
.t-check {
	opacity: 1 !important;
	position: relative !important;
	top: 0.1rem;
	pointer-events: unset !important;
	margin-right: 0.5rem;
}

.pm-space {
	margin-top: 2.5rem;
}

.status.s {
	color: #2fdf84 !important;
}
.status.s::after {
	content: "SUCCESSFUL";
}

.status.f {
	color: #ff2929 !important;
}
.status.f::after {
	content: "FAILED";
}

.status.p {
	color: #e3bc6a !important;
}
.status.p::after {
	content: "PENDING";
}

.rent-relief .pm-tab {
	width: 255px;
}

.pm-tab {
	height: 60px;
	border-radius: 10px 16px 0 0;
	background-color: #e7e7e7;
	padding: 0 3rem 0 1.5rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	width: 280px;
	cursor: pointer;
	-webkit-clip-path: polygon(0 0, 85% 0, 100% 100%, 0% 100%);
	clip-path: polygon(0 0, 85% 0, 100% 100%, 0% 100%);
}
.pm-tab i {
	font-size: 20px;
	margin-right: 1rem;
}
.pm-tab span {
	font-size: 18px;
	font-weight: 600;
	letter-spacing: 0;
	line-height: 30px;
}

.pm-tab.active {
	background-color: white;
}

.pm-tab-count {
	height: 23px;
	width: 55px;
	border-radius: 11.5px;
	background-color: #0042ff;
	font-size: 14px;
	font-weight: 500;
	letter-spacing: 0;
	line-height: 20px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
}

.back-icon {
	font-size: 20px;
	margin-right: 1.5rem;
	cursor: pointer;
}

.listing-full {
	font-size: 24px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 30px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.btn-outline {
	height: 40px;
	width: 180px;
	border: 2px solid #191919;
	border-radius: 3.2px;
	background-color: rgba(255, 255, 255, 0);
	outline: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 14.4px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 23px;
	text-align: center;
	color: #191919;
	margin-bottom: 1rem;
}

.listing-actions {
	margin-right: 2rem;
	margin-top: 2rem;
}

.sch-txt {
	font-size: 20px;
	font-weight: 600;
	letter-spacing: 0.38px;
	line-height: 24px;
}

.sch-txt.bold {
	font-weight: bold;
}

.sm-outline {
	height: 26px;
	width: 94px;
	border: 1.02px solid #191919;
	border-radius: 2.05px;
	background-color: rgba(255, 255, 255, 0);
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 9.22px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 15px;
	cursor: pointer;
}

.sch-container {
	height: 140px;
	width: 310px;
	border: 1px solid #d8d8d8;
	border-radius: 9px;
	background-color: #ffffff;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 1rem;
}

.time-picker {
	height: 25.2px;
	width: 85px;
	font-size: 15.4px;
	font-weight: 500;
	letter-spacing: 0.25px;
	line-height: 19.6px;
	border-radius: 4.2px;
	background-color: rgba(118, 118, 128, 0.12);
	display: flex;
	justify-content: center;
	align-items: center;
}

i.del {
	font-size: 18px;
	color: rgba(0, 0, 0, 0.3);
}

.demo-modal {
	width: 30%;
	background-color: white;
	margin: 0 auto;
	padding: 2rem;
	min-height: 100vh;
}

.property-info-box {
	position: relative;
	width: 100%;
	margin: 2rem auto;
}
.px-2rem {
	padding: 0 2rem !important;
}
.property-img {
	width: 100%;
	height: 200px;
	border-radius: 4px;
	position: relative;
}
.property-img img {
	height: 100%;
	width: 100%;
	object-fit: cover;
}

.property-details-box {
	border-radius: 4px;
	width: 96%;
	height: 100%;
	padding: 1rem;
	background-color: #ffffff;
	margin: 0 auto 0;
	transform: translateY(-50px);
}

.property-box-title {
	width: 182px;
	color: #191919;
	font-family: "Baloo Bhai 2";
	font-size: 16px;
	font-weight: 500;
	letter-spacing: 0;
	line-height: 18px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	margin-bottom: 0;
}

.property-box-sub {
	color: #252427;
	font-family: "Baloo Bhai 2";
	font-size: 14px;
	letter-spacing: 0;
	line-height: 20px;
	font-weight: 400;
}
.property-box-sub i {
	margin-right: 0.5rem;
	font-size: 12px;
	font-weight: 300;
	letter-spacing: 0;
	line-height: 20px;
	color: #0042ff;
}

.property-box-sub.b {
	color: #191919;
	font-size: 14px;
	font-weight: 500;
}

.property-price {
	color: #000000;
	font-family: "Baloo Bhai 2";
	font-size: 20px;
	font-weight: 600;
	letter-spacing: 0;
	line-height: 22.1px;
}

.desc-title {
	color: #191919;
	font-family: "Baloo Bhai 2";
	font-size: 14px;
	font-weight: 500;
	letter-spacing: 0;
	line-height: 18px;
	margin-top: 1.5rem;
}

.description-text {
	color: rgba(25, 25, 25, 0.8);
	font-family: "Baloo Bhai 2";
	font-size: 12px;
	letter-spacing: 0;
	line-height: 20px;
}

ul.desc {
	margin: 0 0 0 1rem;
}

.pic-tab {
	height: 151.97px;
	width: 150px;
	background-color: #515052;
	margin-right: 1rem;
}
.pic-tab:last-child {
	margin-right: 0;
}
.pic-tab img,
.pic-tab video {
	height: 100%;
	width: 100%;
	-o-object-fit: cover;
	object-fit: cover;
}

.item-page {
	font-size: 18px;
	font-weight: 700;
	letter-spacing: 0;
	line-height: 34px;
}

i.cancel {
	font-size: 30px;
	cursor: pointer;
}

.flex-item.cl {
	flex-wrap: wrap;
	border-bottom: 2px solid #f6f6f6;
}

i.b {
	font-size: 20px;
	color: #0042ff;
}

.input-box {
	position: relative;
}

.input-label {
	position: absolute;
	background-color: white;
	padding: 0 3px;
	color: #252427;
	font-family: "Baloo Bhai 2";
	font-size: 14px;
	font-weight: 600;
	letter-spacing: -0.47px;
	line-height: 23px;
	left: 6%;
	top: -11px;
	z-index: 2;
}

.input-label.req {
	font-size: 12px;
}

.formfield,
.input-txt {
	border: 1px solid #dce1e7;
	border-radius: 4px;
	background-color: #ffffff;
	height: 3rem;
	display: flex;
	align-items: center;
	padding-left: 2rem;
	width: 100%;
	color: #252427;
	font-family: "Baloo Bhai 2";
	font-size: 14px;
	letter-spacing: -0.47px;
	line-height: 23px;
	outline: 0;
	margin-bottom: 2rem;
}
.formfield::-moz-placeholder,
.input-txt::-moz-placeholder {
	opacity: 0.3;
}
.formfield:-ms-input-placeholder,
.input-txt:-ms-input-placeholder {
	opacity: 0.3;
}
.formfield::placeholder,
.input-txt::placeholder {
	opacity: 0.3;
}

.formfield.reup,
.reup.input-txt {
	font-size: 20px;
	font-weight: bold;
	color: #000000;
}
.formfield.reup::-moz-placeholder,
.reup.input-txt::-moz-placeholder {
	opacity: 1;
	color: #000000;
}
.formfield.reup:-ms-input-placeholder,
.reup.input-txt:-ms-input-placeholder {
	opacity: 1;
	color: #000000;
}
.formfield.reup::placeholder,
.reup.input-txt::placeholder {
	opacity: 1;
	color: #000000;
}

.input-txt {
	min-height: 7rem;
	resize: none;
	padding-top: 1rem;
}

.formfield.pass,
.pass.input-txt {
	color: #252427;
}
.formfield.pass::-moz-placeholder,
.pass.input-txt::-moz-placeholder {
	opacity: 1;
	font-family: "Baloo Bhai 2";
	font-size: 18px;
	font-weight: bold;
	line-height: 20px;
}
.formfield.pass:-ms-input-placeholder,
.pass.input-txt:-ms-input-placeholder {
	opacity: 1;
	font-family: "Baloo Bhai 2";
	font-size: 18px;
	font-weight: bold;
	line-height: 20px;
}
.formfield.pass::placeholder,
.pass.input-txt::placeholder {
	opacity: 1;
	font-family: "Baloo Bhai 2";
	font-size: 18px;
	font-weight: bold;
	line-height: 20px;
}

.checktext {
	color: #252427;
	font-family: "Baloo Bhai 2";
	font-size: 12px;
	font-weight: 600;
	letter-spacing: -0.47px;
	line-height: 23px;
	margin-bottom: 0 !important;
	margin-left: 0.5rem;
}

.checkbox {
	display: flex;
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
	align-items: center;
	margin-bottom: 1rem;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.checkbox label {
	position: relative;
	cursor: pointer;
}
.checkbox label::before {
	content: "";
	background-color: transparent;
	border: 2px solid #0042ff;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
		inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
	height: 1.2rem;
	width: 1.2rem;
	border-radius: 2px;
	display: inline-block;
	position: relative;
	vertical-align: middle;
	cursor: pointer;
	margin-right: 5px;
}
.checkbox input:checked + label:after {
	content: "";
	color: #ffffff;
	font-family: "Font Awesome 5 Pro";
	font-size: 10px;
	display: block;
	position: absolute;
	top: 50%;
	left: 8%;
	transform: translate(-50%, -50%);
}
.checkbox input:checked + label::before {
	background-color: #0042ff;
}

.demo-modal.lg {
	width: 80%;
}

.secondary-btn {
	border-radius: 6px;
	height: 3.125rem;
	min-width: 21rem;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #0042ff;
	outline: 0;
	border: 0;
	color: white;
	margin: 3rem auto;
}

.kin-head,
.list-tip {
	font-size: 16px;
	font-weight: 600;
	letter-spacing: -0.53px;
	line-height: 26px;
	margin: 2.5rem 0;
	color: black;
}

.list-tip {
	font-size: 18px;
}

.arrow,
.arrows {
	background-color: white;
	height: 2.5rem;
	width: 3rem;
	position: absolute;
	right: 1%;
	top: 9%;
	pointer-events: none;
}
.arrow::after,
.arrows::after {
	content: "";
	color: #252427;
	font-family: "Font Awesome 5 Pro";
	font-size: 12px;
	letter-spacing: -0.4px;
	line-height: 13px;
	text-align: center;
	position: relative;
	top: 18%;
}

.arrows::after {
	content: "";
	left: 50%;
}

.select-box {
	width: 100%;
	height: 3rem;
	position: relative;
	margin-bottom: 2rem;
}

.select-boxx {
	height: 2rem;
	margin-bottom: 2rem;
}
.select-boxx .formfield,
.select-boxx .input-txt {
	height: 2.5rem;
}
.select-boxx .arrows {
	height: 2rem;
}

tbody tr td.user {
	color: #0042ff;
}

.name-side {
	width: 30%;
	background-color: white;
	min-height: 600px;
	padding: 1rem 2rem;
}

.match-side {
	width: 69.8%;
	background-color: white;
	min-height: 600px;
	margin-left: auto;
	padding: 1rem 2rem;
}

.no-bg {
	background-color: transparent !important;
	padding: 0;
}

.listing-full.dull {
	color: rgba(0, 0, 0, 0.3);
	font-weight: 600;
}

.matches-box {
	min-height: 400px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.admin-media-cover {
	width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    cursor: pointer;
}

.admin-media-cover::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	border-radius: 10px;
	background-color: #f5f5f5;
}
  
.admin-media-cover::-webkit-scrollbar {
	height: 4px;
	background-color: #f5f5f5;
}
  
.admin-media-cover::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	background-color: #81efb5;
}

.admin-img-field {
	height: 71px;
	width: 71px;
	border: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	outline: 0;
	border-radius: 6px;
	background-color: #f8f8f8;
	position: relative;
	margin-right: 1rem;
	cursor: pointer;
	flex: 0 0 71px;
	margin-bottom: 1rem;
}
.admin-img-field::before {
	content: "";
	position: absolute;
	color: #8d8d8d;
	font-family: "Font Awesome 5 Pro";
	font-size: 18px;
	letter-spacing: 0;
	line-height: 18px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 1;
	pointer-events: none;
}
.admin-img-field img {
	z-index: 2;
	width: 100%;
	height: 100%;
	-o-object-fit: cover;
	object-fit: cover;
	border-radius: 6px;
}

.admin-img-field.selected::after {
	content: "";
	color: #ffffff;
	font-family: "Font Awesome 5 Pro";
	font-size: 14px;
	font-weight: 500;
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 20px;
	height: 20px;
	border-radius: 6px 0 6px 0;
	background-color: #2fdf84;
	z-index: 4;
}

.admin-form {
	display: block;
	color: #191919;
	font-family: "Baloo Bhai 2";
	font-size: 14px;
	font-weight: 600;
	letter-spacing: 0;
	line-height: 37px;
	margin-bottom: 0.5rem;
}

.adds {
	opacity: 0;
	width: 100%;
	height: 100%;
}

.delete {
	cursor: pointer;
}
.delete::before {
	content: "";
	color: #000000;
	font-family: "Font Awesome 5 Pro";
	font-size: 14px;
	font-weight: 600;
	position: absolute;
}

.primary-btn {
	height: 3rem;
	min-width: 200px;
	border-radius: 6px;
	background-color: #0042ff;
	border: 0;
	outline: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	font-size: 16px;
	font-weight: 600;
	letter-spacing: -0.53px;
	line-height: 26px;
	text-align: center;
}

.all-tabs {
	background-color: #f7f7f7;
	padding: 0.2rem;
	height: 2.5rem;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	border-radius: 4px;
	margin: 1rem 0;
}

.mini-tabs {
	min-width: 160px;
	font-size: 14px;
	font-weight: 600;
	letter-spacing: 0;
	line-height: 0;
	text-align: center;
	color: rgba(25, 25, 25, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	cursor: pointer;
}

.mini-tabs.active {
	color: #0042ff;
	font-weight: bold;
	background-color: white;
}

.single-listing-info {
	width: 65%;
	background-color: white;
	padding: 1.5rem;
	min-height: 500px;
}

.single-listing-preview {
	width: 34.9%;
	margin-left: auto;
	min-height: 500px;
}

.sale-type {
	height: 25px;
	padding: 0 11px;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	font-size: 14px;
	font-weight: 500;
	letter-spacing: 0;
	color: black;
	border-radius: 4px;
	background-color: #dce1e7;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 1rem;
}

.sale-type.pm {
	background-color: #0042ff;
	color: white;
}

.notes {
	font-size: 12px;
	font-weight: 600;
	color: rgba(0, 0, 0, 0.3);
	margin-top: -1.8rem;
	margin-bottom: 1rem;
}

.action-btn {
	height: 34px;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	padding: 0 1rem;
	background-color: transparent;
	display: flex;
	justify-content: center;
	align-items: center;
	outline: 0;
	border: 0;
	font-size: 13.44px;
	font-weight: 600;
	letter-spacing: -0.45px;
	line-height: 21px;
	text-align: center;
	border-radius: 5.04px;
	cursor: pointer;
}

.action-btn.danger {
	background-color: #ff101f !important;
	color: white;
	padding: 0 3rem;
}

.action-btn.success {
	background-color: #2fdf84 !important;
	color: white;
	padding: 0 3rem;
}

.bdm {
	background-color: white;
	height: 5rem;
	margin-bottom: 1px;
	width: 100%;
	padding: 1.5rem;
}

.listing-show {
	background-color: white;
	min-height: 1046px;
	width: 100%;
	padding: 1rem 2.5rem;
}

.listing-location {
	border-radius: 3.78px 0 0 3.78px;
	background-color: #0042ff;
	padding: 0.2rem 0.8rem;
	color: #ffffff;
	font-family: "Baloo Bhai 2";
	font-size: 14px;
	font-weight: 600;
	letter-spacing: 0;
	line-height: 18.9px;
	position: absolute;
	top: 20%;
	right: 0;
	height: 24px;
}

.i-date {
	height: 65px;
	width: 100%;
	border: 1px solid #d8d8d8;
	border-radius: 9px;
	background-color: #ffffff;
	padding: 0 2rem;
	outline: 0;
	font-weight: bold;
	font-size: 20px;
	color: black;
}
.i-date::-moz-placeholder {
	color: black;
}
.i-date:-ms-input-placeholder {
	color: black;
}
.i-date::placeholder {
	color: black;
}

i.prf {
	font-size: 18px;
	color: rgba(0, 0, 0, 0.3);
	background-color: white;
	position: absolute;
	top: 50%;
	right: 8%;
	transform: translateY(-50%);
	padding: 0.5rem;
	pointer-events: none;
	cursor: pointer;
}

.ex-btn {
	height: 2.1rem;
	min-width: 135px;
	padding: 0 1rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.03);
	border: 0;
	outline: 0;
	margin-right: 1rem;
	border-radius: 8px;
}
.ex-btn span {
	font-size: 14px;
	font-weight: 600;
	letter-spacing: 0;
}
.ex-btn i {
	font-size: 1rem;
	color: rgba(0, 0, 0, 0.3);
}

.ns {
	width: 100px;
	height: 2.1rem;
	background-color: #0042ff;
	border-radius: 4px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 1rem;
}
.ns select {
	border: 0;
	font-size: 12px;
	font-weight: 500;
	letter-spacing: 0;
	line-height: 20px;
	color: white;
	outline: 0;
	background-color: transparent;
	width: 100%;
}
.ns .arrow {
	width: 1.8rem;
	height: 1.5rem;
	background-color: #0042ff;
	top: 0;
	right: 0;
}
.ns .arrow::after {
	font-size: 8px;
	color: white;
}

.del-icon {
	height: 30px;
	width: 30px;
	background-color: #df392f;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	border-radius: 50%;
	margin-right: 1.5rem;
}

.accpt-icon {
	height: 30px;
	width: 30px;
	background-color: #2fdf84;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	border-radius: 50%;
}

.sourcing {
	height: 65px;
	min-width: 200px;
	border: 1px solid #f1f1f1;
	border-radius: 3px;
	background-color: #ffffff;
	display: flex;
	align-items: center;
	padding: 0 1.5rem;
	margin: 1rem 1rem 1rem 0;
}

.source-info p {
	font-size: 13px;
	letter-spacing: 0;
	line-height: 20px;
	font-weight: 500;
	color: rgba(0, 0, 0, 0.3);
	margin-bottom: 0.3rem;
}
.source-info h3 {
	font-size: 20px;
	font-weight: 600;
	letter-spacing: 0;
	line-height: 20px;
	color: #000000;
	margin-bottom: 0;
}

.approve-btn {
	height: 40px;
	padding: 0 2rem;
	border-radius: 4px;
	background-color: #0042ff;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 14px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 23px;
	text-align: center;
	outline: 0;
	border: 0;
	color: white;
	margin-left: 2rem;
}

.pic-label {
	font-size: 12px;
	font-weight: 500;
	letter-spacing: 0;
	line-height: 16px;
	color: rgba(15, 15, 15, 0.5);
}

.pic-box {
	height: 109px;
	width: 109px;
	border-radius: 4px;
	overflow: hidden;
	background-color: #191919;
	margin-bottom: 1.5rem;
}
.pic-box img {
	height: 100%;
	width: 100%;
	border-radius: 4px;
	-o-object-fit: cover;
	object-fit: cover;
}

.id-box {
	height: 122px;
	width: 190px;
	border-radius: 4px;
	overflow: hidden;
}
.id-box img {
	height: 90%;
	width: 80%;
	margin: 0 auto;
	background-color: white;
	box-shadow: 0px 2px 5px rgba(118, 118, 128, 0.12);
}

.applicant-details {
	width: 49.9%;
	background-color: white;
	padding: 1.5rem;
	min-height: 1100px;
	margin-right: auto;
}

.owner-details {
	width: 49.9%;
	background-color: white;
	padding: 1.5rem;
	min-height: 1100px;
	margin-left: auto;
}

.sale-type.ten {
	background-color: #2fdf84;
	color: black;
}

.single-rent-top {
	width: 100%;
	height: 9rem;
	background-color: white;
	margin-bottom: 2px;
	padding: 1.5rem;
}

.single-application-info {
	/* background-color: white;	 */
	width: 100%;
	min-height: 1100px;
	padding: 1.5rem;
}
.single-application-details {
	width: 33.3%;
	/* margin-left: auto; */
    min-height: 500px;
	/* border-right: 2px solid #f5f5f5; */
}

.document-wrap {
	border: 1px solid #DCE1E7;
	border-radius: 6px;
	background-color: #FFFFFF;
	color: #252427;
	opacity: 0.8;
}

.col-rent {
	width: 33.3%;
    border-right: 3px solid #f5f5f5;	
}
.col-rent:last-child {
    border-right: none;	
}

.single-rent-btm {
	background-color: white;
	width: 100%;
	min-height: 1100px;
	padding: 1.5rem;
}

.screen-half { 
	width: 59.3%;
}

.screen-third { 
	width: 39.3%;
}
.application-col {
	/* background-color: white; */
	width: 100%;
	min-height: 1100px;
	padding: 1.5rem;
}

.first-rent {
	width: 33.3%;
}

.second-rent {
	width: 33.1%;
	margin: 0 auto;
}

.third-rent {
	width: 33.3%;
}

.bank-info {
	font-family: "Baloo Bhai 2";
	border: 1px solid #F1F1F1;
	border-radius: 3px;
}
.bank-info h6 {
	opacity: 0.5;
	color: #000000;
	font-size: 14px;
}
.bank-info h3 {
	color: #000000;
	font-size: 1.2rem;
}

.rent-btn {
	height: 45px;
	width: 100%;
	border: 1px solid #dce1e7;
	border-radius: 4px;
	background-color: #ffffff;
	display: flex;
	align-items: center;
	padding: 0 1rem;
	color: rgba(37, 36, 39, 0.8);
	font-family: "Baloo Bhai 2";
	font-size: 16px;
	font-weight: 500;
	letter-spacing: -0.53px;
	line-height: 26px;
	margin: 1rem 0;
}
.rent-btn i {
	color: rgba(25, 25, 25, 0.8);
	font-size: 14px;
	margin-right: 1rem;
}

.complaint-list {
	width: 25%;
	background-color: white;
	border-right: 1px solid #f6f6f6;
}
.complaint-list ul {
	width: 100%;
	list-style: none;
}
.complaint-list ul li {
	padding: 1rem;
	border-bottom: 1px solid #f6f6f6;
	color: #191919;
}
.complaint-list ul li h6 {
	font-size: 16px;
	font-weight: 600;
	letter-spacing: 0;
	line-height: 26px;
}
.complaint-list ul li span {
	font-size: 14px;
	letter-spacing: 0;
	line-height: 23px;
}
.complaint-list ul li:hover {
	background-color: #0042ff;
	color: white;
}
.complaint-list ul li.active {
	background-color: #0042ff;
	color: white;
}

.single-complaint-info {
	width: 74.9%;
	margin-left: auto;
	background-color: white;
}
.single-complaint-info ul {
	list-style: none;
}
.single-complaint-info ul li {
	padding: 0.8rem 1rem;
	border-bottom: 1px solid #f6f6f6;
}
.single-complaint-info ul li span {
	margin-bottom: 0.8rem;
	font-size: 14px;
	letter-spacing: 0;
}
.single-complaint-info ul li h6 {
	margin-bottom: 0.5rem;
	font-size: 16px;
	font-weight: 600;
	letter-spacing: 0;
	line-height: 26px;
}

.w-fit {
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
}

.admin-act {
	height: 34px;
	width: 100%;
	background-color: #f8f9fa;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 1rem;
	margin: 0.5rem 0;
}

.admin-act.wh {
	background-color: white;
}

.permision {
	color: #000000;
	font-family: "Baloo Bhai 2";
	font-size: 14px;
	font-weight: 600;
	letter-spacing: 0;
	line-height: 16px;
}

.rep-profile-img {
	height: 60px;
	width: 60px;
	background-color: #f6f6f6;
	border-radius: 50%;
	font-family: "Font Awesome 5 Pro";
	position: relative;
	overflow: hidden;
}
.rep-profile-img::before {
	content: "";
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: rgba(15, 15, 15, 0.26);
}
.rep-profile-img img {
	width: 100%;
	height: 100%;
	-o-object-fit: cover;
	object-fit: cover;
}

.rep-profile-img.lg {
	width: 80px;
	height: 80px;
}

.small-note {
	color: rgba(15, 15, 15, 0.5);
	font-family: "Baloo Bhai 2";
	font-size: 12px;
	font-weight: 600;
	letter-spacing: 0;
	line-height: 16px;
	margin: 0.5rem 0 1.5rem;
}

.img-container {
	max-width: 100%;
	overflow-x: auto;
	overflow-y: hidden;
}
.img-container::-webkit-scrollbar {
	width: 0.4em;
	height: 0.3rem;
	background-color: #f6f6f6;
}
.img-container::-webkit-scrollbar-track {
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.img-container::-webkit-scrollbar-thumb {
	background-color: #0042ff;
}

.single-form {
	margin-top: 1rem;
}
.error {
	background-color: rgba(255, 0, 0, 0.2);
	height: 3rem;
	display: flex;
	align-items: center;
	padding: 0 1.5rem;
	color: red;
	margin-bottom: 2rem;
}
.error::before {
	position: relative;
	content: "\f06a";
	color: red;
	font-family: "Font Awesome 5 Pro";
	padding-right: 0.5rem;
}
.sk {
	display: inline-block;
	height: 100%;
	position: relative;
	overflow: hidden;
	background-color: #dddbdd;
}
.sk::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	transform: translateX(-100%);
	background-image: linear-gradient(
		90deg,
		rgba(255, 255, 255, 0) 0,
		rgba(255, 255, 255, 0.2) 20%,
		rgba(255, 255, 255, 0.5) 60%,
		rgba(255, 255, 255, 0)
	);
	animation: shimmer 2s infinite;
	content: "";
}
@keyframes shimmer {
	100% {
		transform: translateX(100%);
	}
}
.sk span {
	display: none;
}
.sk .pm-user-box {
	display: none;
}
.spin {
	width: 100%;
	height: 100%;
	min-height: 70vh;
	display: flex;
	justify-content: center;
	align-items: center;
}
